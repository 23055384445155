// import React from "react";
// import "./Parter.css";
// import blueprint from "../../../assets/images/PARTER.jpg";

// const Parter = () => {
//   return (
//     <div className="parter-container">
//       <img src={blueprint} alt="Parter" className="parter-blueprint" />
//       <svg className="parter-overlay">
//         <a xlinkHref="/apartamente/ap1-4-10">
//           <polygon
//             points="925,630 620,630 620,765 670,765 670,820 925,820"
//             className="parter-highlight"
//             id="ap1"
//           />
//         </a>
//         <a xlinkHref="/apartamente/ap2">
//           <polygon
//             points="140,425 185,425 185,410 185,410 315,410 315,370 460,370 460,410 520,410 520,645 185,645 185,570 140,570"
//             className="parter-highlight"
//             id="ap2"
//           />
//         </a>
//         <a xlinkHref="/apartamente/ap3">
//           <polygon
//             points="520,415 545,415 545,370 685,370 685,415 975,415 975,585 520,585"
//             className="parter-highlight"
//             id="ap3"
//           />
//         </a>
//         <a xlinkHref="/apartamente/spcom">
//           <polygon
//             points="915,425 915,215 1320,215 1320,425 915,425"
//             className="parter-highlight"
//             id="spcom"
//           />
//         </a>
//       </svg>
//     </div>
//   );
// };

// export default Parter;

import React from "react";
import "./Parter.css";
import blueprint from "../../../assets/images/PARTER.jpg";

const Parter = () => {
  return (
    <div className="parter-container">
      <img src={blueprint} alt="Parter" className="parter-blueprint" />
      <svg
        className="parter-overlay"
        viewBox="0 0 1440 1024"
        preserveAspectRatio="xMidYMid meet"
      >
        <a xlinkHref="/apartamente/ap1-4-10">
          <polygon
            points="925,630 620,630 620,765 670,765 670,820 925,820"
            className="parter-highlight"
            id="ap1"
          />
        </a>
        <a xlinkHref="/apartamente/ap2">
          <polygon
            points="140,425 185,425 185,410 315,410 315,370 460,370 460,410 520,410 520,645 185,645 185,570 140,570"
            className="parter-highlight"
            id="ap2"
          />
        </a>
        <a xlinkHref="/apartamente/ap3">
          <polygon
            points="520,415 545,415 545,370 685,370 685,415 975,415 975,585 520,585"
            className="parter-highlight"
            id="ap3"
          />
        </a>
        <a xlinkHref="/apartamente/spcom">
          <polygon
            points="915,425 915,215 1320,215 1320,425 915,425"
            className="parter-highlight"
            id="spcom"
          />
        </a>
      </svg>
    </div>
  );
};

export default Parter;
