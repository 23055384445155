import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Apartments.css";
import pic1 from "../../../assets/images/1.png";
import pic2 from "../../../assets/images/2.png";
import pic3 from "../../../assets/images/3.png";
import pic4 from "../../../assets/images/4.png";
import pic5 from "../../../assets/images/5.png";

const Apartments = () => {
  return (
    <div className="Container-Sidebar">
      <div className="sidebar">
        <h1 className="apartmentsH1">Alege Etajul</h1>
        <a href="/apartamente/subsol">Plan SUBSOL</a>
        <a href="/apartamente/parter">Plan PARTER</a>
        <a href="/apartamente/etajul1">Plan ETAJUL I</a>
        <a href="/apartamente/etajul2">Plan ETAJUL II</a>
      </div>

      <div className="content">
        <Slide>
          <div className="each-slide-effect">
            <div
              className="slide-image"
              style={{ backgroundImage: `url(${pic1})` }}
            ></div>
          </div>
          <div className="each-slide-effect">
            <div
              className="slide-image"
              style={{ backgroundImage: `url(${pic2})` }}
            ></div>
          </div>
          <div className="each-slide-effect">
            <div
              className="slide-image"
              style={{ backgroundImage: `url(${pic3})` }}
            ></div>
          </div>
          <div className="each-slide-effect">
            <div
              className="slide-image"
              style={{ backgroundImage: `url(${pic4})` }}
            ></div>
          </div>
          <div className="each-slide-effect">
            <div
              className="slide-image"
              style={{ backgroundImage: `url(${pic5})` }}
            ></div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default Apartments;
