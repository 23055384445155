import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/atoms/Header/Header";
import Footer from "./components/molecules/Footer/Footer";
import Homepage from "./components/pages/Homepage/Homepage";
import Concept from "./components/pages/Concept/Concept";
import Facilities from "./components/pages/Facilities/Facilities";
import Apartments from "./components/pages/Apartments/Apartments";
import Subsol from "./components/pages/Subsol/Subsol";
import Parter from "./components/pages/Parter/Parter";
import Etajul1 from "./components/pages/Etajul1/Etajul1";
import Etajul2 from "./components/pages/Etajul2/Etajul2";
import AP1410 from "./components/pages/AP1-4-10/AP1-4-10";
import AP2 from "./components/pages/AP2/AP2";
import AP3 from "./components/pages/AP3/AP3";
import AP5 from "./components/pages/AP5/AP5";
import AP6 from "./components/pages/AP6/AP6";
import AP713 from "./components/pages/AP7-13/AP7-13";
import AP814 from "./components/pages/AP8-14/AP8-14";
import AP915 from "./components/pages/AP9-15/AP9-15";
import AP11 from "./components/pages/AP11/AP11";
import AP12 from "./components/pages/AP12/AP12";
import SPCOM from "./components/pages/SPCOM/SPCOM";
import About from "./components/pages/About/About";
import Contact from "./components/pages/Contact/Contact";
import Confidentialitate from "./components/pages/Confidentialitate/Confidentialitate";
import CookiesPolicy from "./components/pages/Cookies/Cookies";
import "./App.css";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Header />
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route path="/concept" element={<Concept />} />
        <Route path="/facilitati" element={<Facilities />} />
        <Route path="/apartamente" element={<Apartments />} />
        <Route path="/apartamente/subsol" element={<Subsol />} />
        <Route path="/apartamente/parter" element={<Parter />} />
        <Route path="/apartamente/etajul1" element={<Etajul1 />} />
        <Route path="/apartamente/etajul2" element={<Etajul2 />} />
        <Route path="/apartamente/ap1-4-10" element={<AP1410 />} />
        <Route path="/apartamente/ap2" element={<AP2 />} />
        <Route path="/apartamente/ap3" element={<AP3 />} />
        <Route path="/apartamente/ap5" element={<AP5 />} />
        <Route path="/apartamente/ap6" element={<AP6 />} />
        <Route path="/apartamente/ap7-13" element={<AP713 />} />
        <Route path="/apartamente/ap8-14" element={<AP814 />} />
        <Route path="/apartamente/ap9-15" element={<AP915 />} />
        <Route path="/apartamente/ap11" element={<AP11 />} />
        <Route path="/apartamente/ap12" element={<AP12 />} />
        <Route path="/apartamente/spcom" element={<SPCOM />} />
        <Route path="/despre-noi" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/politica-confidentialitate"
          element={<Confidentialitate />}
        />
        <Route path="/politica-cookies" element={<CookiesPolicy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
