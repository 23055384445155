import React from "react";
import "./Etajul1.css";
import blueprint from "../../../assets/images/ETAJ1.jpg";

const Etajul1 = () => {
  return (
    <div className="etajul1-container">
      <img src={blueprint} alt="Etajul1" className="etajul1-blueprint" />
      <svg
        className="etajul1-overlay"
        viewBox="0 0 1440 1024"
        preserveAspectRatio="xMidYMid meet"
      >
        <a xlinkHref="/apartamente/ap1-4-10">
          <polygon
            points="925,630 620,630 620,765 670,765 670,820 925,820"
            className="etajul1-highlight"
            id="ap4"
          />
        </a>
        <a xlinkHref="/apartamente/ap5">
          <polygon
            points="160,430 190,430 190,420 190,420 320,420 320,375 465,375 465,425 520,425 520,590 570,590 570,650 190,650 190,575 160,575"
            className="etajul1-highlight"
            id="ap5"
          />
        </a>
        <a xlinkHref="/apartamente/ap6">
          <polygon
            points="520,415 545,415 545,370 685,370 685,415 795,415 795,585 520,585"
            className="etajul1-highlight"
            id="ap6"
          />
        </a>
        <a xlinkHref="/apartamente/ap7-13">
          <polygon
            points="795,420 910,420 910,215 1140,215 1140,475 1090,475 1090,590 795,590"
            className="etajul1-highlight"
            id="ap7"
          />
        </a>
        <a xlinkHref="/apartamente/ap8-14">
          <polygon
            points="1140,215 1375,215 1375,520 1140,520"
            className="etajul1-highlight"
            id="ap8"
          />
        </a>
        <a xlinkHref="/apartamente/ap9-15">
          <polygon
            points="1140,520 1375,520 1375,830 1140,830"
            className="etajul1-highlight"
            id="ap9"
          />
        </a>
      </svg>
    </div>
  );
};

export default Etajul1;
