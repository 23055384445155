// import React from "react";
// import "./Header.css";

// const Header = () => {
//   return (
//     <div className="header">
//       <a href="/" className="logo">
//         <img src="../../../assets/logo/LOGO.png" alt="logo" />
//       </a>
//       <div className="header-right">
//         <a href="/">Home</a>
//         <a href="/concept">Concept</a>
//         <a href="/facilitati">Facilități</a>
//         <a href="/apartamente">Apartamente</a>
//         <a href="/despre-noi">Despre Noi</a>
//         <a href="/contact">Contact</a>
//       </div>
//     </div>
//   );
// };

// export default Header;

import React, { useState } from "react";
import "./Header.css";
import Logo from "../../../assets/logo/LOGO.png";

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <div className="header">
      <a href="/" className="logo">
        <img src={Logo} alt="logo" />
      </a>
      <div className="menu-toggle" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`header-right ${menuActive ? "active" : ""}`}>
        <a href="/">Home</a>
        <a href="/concept">Concept</a>
        <a href="/facilitati">Facilități</a>
        <a href="/apartamente">Apartamente</a>
        <a href="/despre-noi">Despre Noi</a>
        <a href="/contact">Contact</a>
      </div>
    </div>
  );
};

export default Header;
