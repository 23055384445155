import React from "react";
import "./Cookies.css";

const CookiesPolicy = () => {
  <div className="cookies">
    <h1>Politica de Utilizare Cookie-uri</h1>
    <p>
      Site-ul nostru web utilizează cookie-uri pentru a vă furniza o experiență
      mai bună de navigare și servicii adaptate nevoilor și interesului
      fiecăruia. Vă rugăm să acordați atenție aspectelor prezentate în cele ce
      urmează, respectiv la mesajul ce apare la accesarea site-ului nostru prin
      intermediul căreia puteți decide dacă sunteți sau nu de acord ca site-ul
      nostru să salveze fișiere de tip cookie în browserul Dvs.
    </p>
    <h3>1. CE ESTE UN COOKIE?</h3>
    <p>
      Cookie-ul este un fișier de mici dimensiuni, format din litere și cifre,
      care va fi stocat pe computerul, terminalul mobil sau orice alt echipament
      de pe care utilizatorul accesează internetul. Cookie-ul este instalat prin
      solicitarea emisă de către un web-server unui browser (ex: Internet
      Explorer, Chrome, Edge, etc.) și este complet 'pasiv'. Mai exact, nu
      conține programe software, viruși sau spyware și nu poate accesa
      informațiile de pe hard-ul (spațiul de stocare) al echipamentului utilizat
      de Dvs. pentru accesarea site-ului web.
    </p>
    <h3>2. LA CE SUNT FOLOSITE COOKIE-URILE?</h3>
    <p>
      Aceste fișiere fac posibilă recunoașterea terminalului utilizatorului și
      prezentarea conținutului într-un mod relevant, adaptat preferințelor
      acestuia. Cookie-urile au rolul să vă asigure o experiență plăcută de
      navigare, utilizare a paginii noastre web și susțin eforturile noastre de
      a vă oferi servicii confortabile: de exemplu stochează preferințele Dvs.
      în materie de confidențialitate online, coșul de cumpărături (în cazul
      site-urilor de tip magazin online) sau vă oferă publicitate relevantă. De
      asemenea, sunt utilizate în pregătirea unor statistici anonime agregate
      care ne ajută să înțelegem modul în care utilizatorul beneficiază de
      paginile noastre web, permițându-ne îmbunătățirea permanentă a structurii
      și conținutului lor, excluzând identificarea personală a utilizatorului
      sau colectarea oricăror informații de natură personală despre aceasta.
    </p>
    <h3>3. CARE ESTE DURATA DE VIAȚĂ A UNUI COOKIE?</h3>
    <p>
      Durata de viață a unui cookie poate fi diferită, în funcție de scopul
      pentru care este plasat. Există cookie-uri folosite exclusiv pentru o
      singură sesiune, acestea nemaifiind reținute după ce utilizatorul
      părăsește site-ul web, și există așa numite cookie-uri permanente, care
      sunt reținute (salvate) și refolosite de fiecare dată când utilizatorul
      revine pe acel website, însă pot fi șterse oricând, manual, de utilizator.
    </p>
    <h3>4. CE COOKIE-URI FOLOSIM?</h3>
    <p>
      Folosim două tipuri de Cookie-uri: de sesiune și persistente. Cele de
      sesiune sunt fișiere temporare care rămân în terminalul utilizatorului
      până la terminarea sesiunii de navigare sau închiderea aplicației
      (browser-ului web). Cele fixe însă, rămân stocate pe terminalul (hard
      drive-ul) utilizatorului pe o perioadă definită de parametrii Cookie-ului
      sau până ce sunt șterse manual de utilizator.
    </p>
    <h3>
      5. CUM ȘI ÎN CE SCOPURI SUNT FOLOSITE COOKIE-URILE DE CĂTRE SITE-UL
      NOSTRU?
    </h3>
    <p>
      O vizită pe site-ul nostru poate plasa următoarele tipuri de cookie-uri:
    </p>
    <p>
      Cookie-uri de performanță a site-ului: acest tip de cookie reține
      preferințele utilizatorului pe acest site, astfel încât nu mai este nevoie
      de setarea lor la fiecare vizitare a site-ului. Spre exemplu: setările
      volumului pentru video player, viteza de video streaming cu care este
      compatibil browserul.
    </p>
    <p>
      Cookie-uri de analiză a vizitatorilor: de fiecare dată când un utilizator
      vizitează acest site, softul de analiză (Google analytics) furnizat de o
      terță parte (în cazul nostru Google Inc.) generează un cookie de analiză a
      utilizatorului. Acest cookie ne spune dacă ați mai vizitat acest site până
      acum. Browserul ne va spune dacă aveți acest cookie, iar daca nu, vom
      genera unul. Acesta permite monitorizarea utilizatorilor unici care ne
      vizitează și cât de des o fac. Acest cookie nu poate fi folosit pentru a
      identifica persoanele fizice, ele sunt folosite doar în scop statistic.
      Dacă sunteți înregistrat pe site-ul nostru (unde există posibilitatea
      creării unui cont) putem ști, de asemenea, detaliile pe care ni le-ați
      furnizat, cum ar fi adresa de e-mail, nume și prenume, acestea fiind
      supuse confidențialității și prevederilor din Termeni si Condiții,
      Politica de confidențialitate precum și prevederilor legislației în
      vigoare cu privire la protejarea datelor cu caracter personal.
    </p>
    <p>
      Cookie-uri de înregistrare: atunci când vă înregistrați pe site-ul nostru
      (unde există posibilitatea creării unui cont), generăm un cookie care ne
      anunță dacă sunteți înregistrat sau nu. Serverele noastre folosesc aceste
      cookie-uri pentru a ne arăta contul cu care sunteți înregistrat și dacă
      aveți permisiunea pentru un serviciu anume. De asemenea, ne permite să
      asociem orice comentariu pe care îl postați pe site-ul nostru cu
      username-ul dvs.
    </p>
    <p>
      Cookie-uri pentru publicitate: Aceste cookie-uri ne permit să aflăm dacă
      ați vizualizat sau nu o reclamă online, care este tipul acesteia și ce
      durată de timp a trecut de când ați văzut mesajul publicitar. Aceste
      cookie-uri se folosesc și pentru a targeta publicitatea online. Putem
      folosi, de asemenea, cookie-uri aparținând unei terțe părți, pentu o mai
      bună targetare a publicității, pentru a arăta de exemplu reclame despre
      anumite produse, dacă utilizatorul a vizitat recent un articol pe site
      despre acele produse. Aceste cookie-uri sunt anonime, ele stochează
      informații despre conținutul vizualizat, nu despre utilizatori. De
      asemenea, setăm cookie-uri anonime și prin alte site-uri pe care avem
      publicitate. Primindu-le, astfel, noi le putem folosi pentru a vă
      recunoaște ca vizitator și vă vom putea livra publicitatea bazată pe
      această informație.
    </p>
    <h3>6. CONȚIN COOKIE-URILE DATE PERSONALE?</h3>
    <p>
      Cookie-urile în sine nu solicită informații cu caracter personal pentru a
      putea fi utilizate și în cele mai multe cazuri, nu identifică personal
      utilizatorii de internet. Eventualele date personale colectate prin
      utilizarea Cookie-urilor pot avea unicul scop de a facilita anumite
      funcționalități pentru utilizator. Aceste date sunt criptate într-un mod
      care face imposibil accesul persoanelor neautorizate la ele.
    </p>
    <h3>7. ȘTERGEREA COOKIE-URILOR</h3>
    <p>
      În general, aplicațiile folosite pentru accesarea paginilor web permit
      salvarea Cookie-urilor pe terminal în mod implicit. Controlul însă vă
      aparține: La prima accesare site-ul vă oferă opțiunea să consultați
      politica noastră de cookie-uri și să decideți dacă acceptați sau nu
      salvarea cookie-urilor. Prin accesarea opțiunii “Accept” îi acordați
      site-lui nostru posibilitatea să salveze cookie-uri în browser-ul,
      respectiv pe echipamentul Dvs. de pe care accesați site-ul. În caz de
      “Refuz” site-ul nostru nu va salva cookie-uri în browserul, respectiv pe
      hard drive-ul echipamentului Dvs. Setările implicite ale browserului pot
      fi schimbate, de asemenea, în așa fel încât administrarea automată a
      Cookie-urilor să fie blocată sau să fiți informat de fiecare dată când
      Cookie-uri sunt trimise către terminalul Dvs. Informații detaliate despre
      posibilitățile și modurile de administrare a Cookie-urilor pot fi găsite
      în zona de setări ale aplicației (browser-ului web). Limitarea folosirii
      Cookie-urilor poate afecta anumite funcționalități ale paginii web.
    </p>
    <h3>8. DE CE SUNT COOKIE-URILE IMPORTANTE PENTRU INTERNET?</h3>
    <p>
      Cookie-urile reprezintă punctul central al funcționării eficiente a
      Internetului, ajutând la generarea unei experiențe de navigare prietenoase
      și adaptată preferințelor și intereselor fiecărui utilizator. Refuzarea
      sau dezactivarea cookie-urilor poate face unele site-uri imposibil de
      folosit. Refuzarea sau dezactivarea cookie-urilor nu înseamnă că nu veți
      mai primi publicitate online, ci doar că aceasta nu va mai putea ține cont
      de preferințele și interesele dvs., evidențiate prin comportamentul de
      navigare.
    </p>
    <p>
      Exemple de întrebuințări importante ale cookie-urilor (care nu necesită
      autentificarea unui utilizator prin intermediul unui cont):
    </p>
    <ol>
      <li>
        Conținut și servicii adaptate preferințelor utilizatorului – categorii
        de produse și servicii;
      </li>
      <li>
        Oferte adaptate pe interesele utilizatorului – reținerea parolelor
        Reținerea filtrelor de protecție a copiilor privind conținutul pe
        Internet (opțiuni family mode, funcții de safe search)
      </li>
      <li>
        Limitarea frecvenței de difuzare a reclamelor – limitarea numărului de
        afișări a unei reclame pentru un anumit utilizator pe un site
      </li>
      <li>
        Furnizarea de publicitate mai relevantă pentru utilizator - măsurarea,
        optimizarea și caracteristicile de analytics, cum ar fi confirmarea unui
        anumit nivel de trafic pe un website, ce tip de conținut este vizualizat
        și modul cum un utilizator ajunge pe un website (ex. prin motoare de
        căutare, direct, din alte website-uri etc). Website-urile derulează
        aceste analize a utilizării lor pentru a îmbunătăți site-urile în
        beneficiul utilizatorilor.
      </li>
    </ol>
  </div>;
};

export default CookiesPolicy;
