import React from "react";
import "./Etajul2.css";
import blueprint from "../../../assets/images/ETAJ2.jpg";

const Etajul2 = () => {
  return (
    <div className="etajul2-container">
      <img src={blueprint} alt="Etajul2" className="etajul2-blueprint" />
      <svg
        className="etajul2-overlay"
        viewBox="0 0 1440 1024"
        preserveAspectRatio="xMidYMid meet"
      >
        <a xlinkHref="/apartamente/ap1-4-10">
          <polygon
            points="855,630 565,630 565,845 855,845"
            className="etajul1-highlight"
            id="ap10"
          />
        </a>
        <a xlinkHref="/apartamente/ap11">
          <polygon
            points="85,390 235,390 235,380 265,380 265,390 310,390 310,380 340,380 340,390 390,390 390,580 450,580 450,650 85,650"
            className="etajul1-highlight"
            id="ap11"
          />
        </a>
        <a xlinkHref="/apartamente/ap12">
          <polygon
            points="390,390 430,390 430,380 465,380 465,390 535,390 535,380 575,380 575,390 645,390 645,380 685,380 685,390 700,390 700,580 390,580"
            className="etajul1-highlight"
            id="ap12"
          />
        </a>
        <a xlinkHref="/apartamente/ap7-13">
          <polygon
            points="700,390 830,390 830,360 835,360 835,285 830,285 830,250 835,250 835,165 1095,165 1095,450 1035,450 1035,580 700,580"
            className="etajul1-highlight"
            id="ap13"
          />
        </a>
        <a xlinkHref="/apartamente/ap8-14">
          <polygon
            points="1095,165 1355,165 1355,195 1360,195 1360,230 1355,230 1355,320 1360,320 1360,350 1355,350 1355,440 1360,440 1360,475 1355,475 1355,500 1095,500"
            className="etajul1-highlight"
            id="ap14"
          />
        </a>
        <a xlinkHref="/apartamente/ap9-15">
          <polygon
            points="1090,495 1355,495 1355,525 1360,525 1360,560 1355,560 1355,670 1360,670 1360,705 1355,705 1355,785 1360,785 1360,820 1355,820 1355,855 1090,855"
            className="etajul1-highlight"
            id="ap15"
          />
        </a>
      </svg>
    </div>
  );
};

export default Etajul2;
