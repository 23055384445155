import React, { useRef } from "react";
import emailjs from "emailjs-com";
import "./ContactForm.css";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_137oyxk",
        "template_km52tb8",
        form.current,
        "lLIPCq6zkwPwtD8Sr"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Mesajul a fost trimis!");
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          alert(
            "Ne pare rau, a intervenit o problema iar mesajul dumneavoastra nu a fost trimis. Va rugam incercati mai tarziu"
          );
        }
      );
  };

  return (
    <div className="container-contact-form">
      <h1>Contactează-ne</h1>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="nume" placeholder="Nume" required />
        <input type="tel" name="telefon" placeholder="Telefon" required />
        <input type="email" name="email" placeholder="Email" required />
        <label>
          <p>De unde ați aflat de noi?</p>
          <select name="select" required>
            <option value="" disabled selected>
              -
            </option>
            <option value="instagram">Instagram</option>
            <option value="facebook">Facebook</option>
            <option value="banner">Banner</option>
            <option value="anunt">Anunt</option>
            <option value="prieteni">De la prieteni</option>
          </select>
        </label>
        <textarea name="mesaj" placeholder="Mesaj" required />
        <br />
        <label>
          <input type="checkbox" name="confidentialitate" required /> Am luat la
          cunoștință
          <a href="/confidentialitate">Politica de confidențialitate</a>
        </label>
        <br />
        <label>
          <input type="checkbox" name="acord" required /> Sunt de acord să
          primesc informări comerciale referitoare la proiectul Form Properties
        </label>
        <br />
        <input type="submit" value="TRIMITE" />
      </form>
    </div>
  );
};

export default ContactForm;
