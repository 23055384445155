import React from "react";
import "./ContactDetails.css";

const ContactDetails = () => {
  return (
    <div className="Container-Contact-Details">
      <div className="Left">
        <p>LOCAȚIE</p>
        <p>Strada Miron Costin, nr. 36, Oradea</p>
      </div>
      <div className="Center">
        <p>ORAR</p>
        <p>09:00 - 18:00</p>
      </div>
      <div className="Right">
        <p>CONTACTEAZĂ-NE</p>
        <p>+40 729 381 518 / contact@formhybrid.ro</p>
      </div>
    </div>
  );
};

export default ContactDetails;
