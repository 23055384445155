import React from "react";
import ContactForm from "../../atoms/ContactForm/ContactForm";
import ContactDetails from "../../atoms/ContactDetails/ContactDetails";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="Container-Contact">
      <div className="Contact">
        <ContactForm />
        <iframe
          title="harta"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.6157462184838!2d21.945223876034376!3d47.04777042640069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474647f65ac53a39%3A0x85ec8097addeae4!2sStrada%20Miron%20Costin%2036%2C%20Oradea!5e0!3m2!1sen!2sro!4v1720704140849!5m2!1sen!2sro"
          width="100%"
          height="auto"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <ContactDetails />
    </div>
  );
};

export default Contact;
