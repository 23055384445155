import React from "react";
import "./Confidentialitate.css";

const Confidentialitate = () => {
  return (
    <div className="confidentialitate">
      <h1>
        Politica de Confidențialitate și Protecția Datelor cu Caracter Personal
      </h1>
      <p>
        Considerăm confidențialitatea informațiilor aduse la cunoștința Form
        Properties ca fiind o componentă esențială a activității comerciale și a
        business-ului în general. Politica și practicile noastre de protejare a
        datelor se focusează pe: colectarea, procesarea, schimbul și stocarea
        corespunzătoare și legale a informațiilor cu caracter personal și
        totodată pe asigurarea confidențialității, integrității și
        disponibilității acestora.
      </p>
      <p>
        Siguranța datelor dumneavoastră este prioritatea noastră numărul unu. În
        cadrul acestei obligații fundamentale, noi, Form Properties, ne angajăm
        să protejăm și să utilizăm în mod corespunzător informațiile cu caracter
        personal colectate prin intermediul site-ului nostru web.
      </p>
      <p>
        Prin urmare, începând cu data de 25 mai 2018, ne-am aliniat la
        standardele europene în vigoare privind prelucrarea datelor cu caracter
        personal și libera circulație a acestor date, stipulate în Regulamentul
        2016/679/UE (GDPR - General Data Protection Regulation, Regulamentul
        general de protecție a datelor cu caracter personal).
      </p>
      <h3>Ce este GDPR?</h3>
      <p>
        GDPR (Regulamentul general de protecție a datelor cu caracter personal)
        este un act legislativ obligatoriu, care vine în sprijinul persoanelor
        fizice în ceea ce privește prelucrarea, protecția și libera circulație a
        datelor cu caracter personal prin crearea a noi drepturi și prin
        preluarea, respectiv consolidarea unor drepturi existente sub Directiva
        95/46/EC, abrogată cu data de 25 mai 2018. Astfel, orice operator
        (societate comercială, entitate) care colectează și prelucrează date cu
        caracter personal trebuie să aplice noile cerințe și să ofere în mod
        transparent informații cu privire la modul în care datele sunt
        prelucrate.
      </p>
      <h3>De ce vă prelucrăm datele?</h3>
      <p>
        Datele Dvs. cu caracter personal (nume, prenume, adresă de mail, număr
        de telefon, adresă) care se regăsesc în evidențele noastre sunt
        prelucrate exclusiv în scopul pentru care au fost colectate, acela de a
        îndeplini cu succes procesele desfășurate în relația cu Dvs., cum ar fi:
        realizarea proceselor de vânzare, realizarea proceselor de livrare,
        informare, notificări, promovare produse și servicii. Orice prelucrare
        ale datelor personale în alte scopuri decât cele menționate în cele ce
        urmează va fi efectuată doar cu acordul Dvs.exprimat în prealabil.
      </p>
      <h3>Ce categorii de date prelucrăm și când le colectăm?</h3>
      <p>
        Datele cu caracter personal cum ar fi: nume, prenume, număr de telefon,
        adresă de e-mail, adresă sunt prelucrate de Form Properties doar în
        situația în care Dumneavoastră le introduceți în rubricile dedicate pe
        site sau ni le trimiteți prin e-mail.
      </p>
      <h3>Datele pentru newsletter:</h3>
      <p>
        Prin bifarea căsuței aferente vă exprimați acordul de a primi de la noi,
        periodic, mesaje de marketing direct. Prin diferitele acțiuni de
        marketing direct vă trimitem informații referitoare la cele mai noi
        produse și servicii din oferta noastră, oferte speciale, campanii
        publicitare. Deși ne dorim să vă avem aproape și să vă putem furniza
        cele mai actuale informații despre activitatea noastră, prin apăsarea
        butonului “Dezabonare” sau prin transmiterea unui e-mail la adresa
        puteți să renunțați oricând la acest serviciu.
      </p>
      <h3>
        Date suplimentare necesare pentru desfășurarea cu succes a procesului de
        vânzare:
      </h3>
      <p>
        Datele cu caracter personal colectate și stocate vor fi prelucrate în
        scopul derulării contractelor și procesării solicitărilor Dumneavoastră.
        Ulterior finalizării derulării solicitării Dvs., a procesului de vânzare
        sau a contractului, după caz, datele Dvs. sunt salvate ținând cont de
        termenele de păstrare conform legislației fiscale și comerciale și acolo
        unde este cazul, pe durata necesară protejării drepturilor operatorului.
      </p>
      <h3>Datele colectate prin intermediul formularului de contact:</h3>
      <p>
        Informațiile minimale pe care trebuie să ni le oferiți sunt: nume și
        prenume, adresa de mail, număr de telefon și bineînțeles mesajul Dvs.
        Prin completarea și transmiterea datelor vă exprimați consimțământul de
        vă colecta și a vă utiliza datele în corespondența inițiată de Dvs.,
        respectiv pentru soluționarea solicitării pentru care ne-ați contactat.
      </p>
      <h3>Care este temeiul legal conform căreia prelucrăm aceste date?</h3>
      <p>
        Temeiul legal conform căreia colectăm, prelucrăm și stocăm aceste date
        este interesul nostru legitim de a asigura funcționalitățile generale
        ale site-ului, de a executa un contract sau o solicitare din partea
        Dvs., interesul nostru comercial în cazul în care achiziționați produse
        de la compania noastră, respectiv consimțământul acordat în momentul
        înregistrării datelor.
      </p>
      <h3>Ce trebuie să știți despre GDPR?</h3>
      <h5>Definiții:</h5>
      <p>
        Prin 'Date cu caracter personal' se înțelege orice informație privind o
        persoană fizică identificată sau identificabilă ('persoana vizată'); O
        persoana fizică identificabilă este o persoană care poate fi
        identificată, direct sau indirect, în special prin referire la un
        element de identificare, cum ar fi un nume, un număr de identificare,
        date de localizare, un identificator online, sau la unul sau mai multe
        elemente specifice, proprii identității sale fizice, fiziologice,
        genetice, psihice, economice, culturare sau sociale. Prelucrarea datelor
        cu caracter personal = orice operațiune sau set de operațiuni efectuate
        asupra datelor cu caracter personal sau asupra seturilor de date cu
        caracter personal, cu sau fără utilizarea de mijloace automatizate, cum
        ar fi: colectarea, înregistrarea, organizarea, structurarea, stocarea,
        adaptarea ori modificarea, extragerea, consultarea, utilizarea,
        dezvăluirea către terți prin transmitere, diseminare sau în orice alt
        mod, alăturarea ori combinarea, blocarea/restricționarea, ștergerea sau
        distrugerea; Prin operator se înțelege orice persoană fizică sau
        juridică, autoritate publică, agenție sau alt organism care, singur sau
        împreună cu altele, stabilește scopurile și mijloacele de prelucrare a
        datelor cu caracter personal.
      </p>
      <h3>Drepturile dumneavoastră</h3>
      <p>
        Noul Regulament conferă mai multe drepturi persoanelor cărora li se
        prelucrează datele cu caracter personal:
      </p>
      <ul>
        <li>
          Dreptul de acces înseamnă că aveți dreptul de a obține o confirmare
          din partea noastră că prelucrăm sau nu datele Dvs., iar dacă da, să vă
          oferim acces la aceste date precum și informații despre cum sunt
          prelucrate;
        </li>
        <li>
          Dreptul la portabilitate se referă la faptul că puteți primi datele
          personale într-un format structurat, care poate fi citit automat și la
          dreptul că acestea pot fi transmise direct altui operator;
        </li>
        <li>
          Dreptul la opoziție vizează dreptul de a vă opune prelucrării datelor
          personale atunci când aceasta deservește un interes public ori un
          interes legitim al nostru;
        </li>
        <li>
          Dreptul la rectificare se referă la corectarea fără întârzieri
          nejustificate, a datelor personale inexacte;
        </li>
        <li>
          Dreptul la ștergere/dreptul de a fi uitat înseamnă că aveți dreptul să
          vă ștergem datele colectate fără întârzieri nejustificate, în oricare
          din următoarele situații: datele nu mai sunt necesare pentru
          îndeplinirea scopurilor pentru care au fost colectate, v-ați retras
          consimțământul și nu există alt temei juridic pentru prelucrare, vă
          opuneți prelucrării, datele au fost colectate ilegal, datele trebuie
          șterse pentru respectarea unei obligații legale;
        </li>
        <li>
          Dreptul la restricționarea prelucrării poate fi exercitat dacă se
          contestă exactitatea datelor pe o anumită perioadă, suficientă pentru
          verificarea datelor, dacă prelucrarea este ilegală dar nu se dorește
          ștergerea datelor doar restricționarea, în cazul în care Form
          Properties nu mai are nevoie de datele personale în vederea
          prelucrării însă ni le cereți pentru apărarea unui drept în instanță,
          dacă v-ați opus prelucrării pentru intervalul de timp cât se verifică
          dacă drepturile legitime prevalează asupra drepturilor tale.
        </li>
      </ul>
      <h3>Cum ne puteți contacta?</h3>
      <p>
        Toate drepturile care vă revin conform prevederilor GDPR și a
        legislației aplicabile, în vigoare le puteți exercita printr-o cerere
        scrisă, datată, semnată și transmisă către noi (Scala Development),
        prin:
      </p>
      <ul>
        <li>telefon la de luni până vineri între orele 09:00 - 18:00;</li>
        <li>e-mail la contact@formhybrid.ro.</li>
      </ul>
      <p>
        În plus, ne puteți trimite orice întrebare sau nemulțumire la adresa de
        e-mail contact@formhybrid.ro, iar dacă sunteți de părere că v-am
        încălcat vreun drept privind acest subiect contactați-ne în cel mai
        scurt timp posibil la telefon la de luni până vineri între orele 09:00
        și 17:00. Dacă nu vă soluționăm solicitarea în termenul legal de 30 de
        zile, vă puteți adresa Autorității Naționale de Supraveghere a
        Prelucrării Datelor cu Caracter Personal. (www.dataprotection.ro)
      </p>
      <h3>În cât timp răspundem la solicitările primite de la Dvs?</h3>
      <p>
        Conform prevederilor GDPR, În termen de cel mult 30 de zile de la data
        primirii cererii Dvs. vă vom furniza toate informațiile necesare
        referitor la îndeplinirea solicitării Dvs. sau acolo unde este cazul vă
        vom comunica motivele documentate pentru care nu am putut da curs
        cererii Dvs.
      </p>
      <p>
        Vă asigurăm asupra faptului că înainte de a da curs oricărei cereri
        privind datele cu caracter personal prelucrate de compania noastră, vom
        lua toate măsurile necesare și rezonabile pentru a verifica identitatea
        solicitantului.
      </p>
      <p>
        Conform prevederilor GDPR, termenul de răspuns de 30 de zile, menționat
        mai sus poate fi prelungit cu cel mult două luni în cazul în care
        complexitatea și numărul cererilor justifică acest lucru, urmând să fiți
        informați cu privire la acest aspect, dacă va fi cazul.
      </p>
      <h3>Transmiterea datelor către autorități</h3>
      <p>
        Transmiterea datelor către instituții publice, instanțe judecătorești,
        precum și autorități competente să cerceteze săvârșirea faptelor penale:
        Form Properties transmite date cu caracter personal în scopurile
        menționate doar în cazul în care acesta se impune prin lege.
      </p>
      <h3>Rețele de socializare</h3>
      <p>
        Site-urile noastre web utilizează așa numite plugin-uri de la operatorii
        de rețele de socializare: facebook și instagram, în scopul de a face
        legătura între pagina web și rețelele de socializare unde are deschis
        compania cont. Prin intermediul acestor plugin-uri nu colectăm date cu
        caracter personal de la Dvs.
      </p>
      <h3>ACTUALIZĂRI ALE POLITICII DE CONFIDENȚIALITATE</h3>
      <p>
        Form Properties poate modifica această politică de confidențialitate în
        situațiile cerute și conform cu Regulamentul General de Protecție a
        Datelor EU 679/2016. Dorim să vă asigurăm că informațiile dvs. sunt
        sigure. Pentru a preveni accesul neautorizat sau divulgarea, am pus în
        aplicare proceduri fizice, electronice și manageriale adecvate pentru a
        proteja și securiza informațiile pe care le colectăm online, în
        conformitate cu Regulamentul General de Protecție a Datelor EU 679/2016.
      </p>
    </div>
  );
};

export default Confidentialitate;
